<template>
  <div>
    <div class="card">
      <div class="card-header pb-1 pt-1">
        <div class="card-title">Cihazlar</div>
        <div v-if="_other_sf.length > 0">
          <b-dropdown
            class="mr-1"
            size="sm"
            :text="_other_sf.length + ' Geçmiş'"
          >
            <b-dropdown-item
              v-for="(item, index) in _other_sf"
              :key="index"
              @click="redirectto(item)"
            >
              {{ moment(item.sf_date).format("DD.MM.YYYY") }}
              #{{ item.sf_id }}
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
      <div class="card-body p-0">
        <b-table
          ref="table_device_list"
          responsive
          striped
          hover
          small
          selectable
          select-mode="single"
          :items="list_device"
          :fields="datasource.table_device_list.fields"
          @row-selected="setDevice"
          :tbody-tr-class="rowClass"
          class="mb-0 align-top"
        >
          <template #cell(due_date_item)="data">
            <div>
              <span
                class="mr-1 badge badge-danger"
                v-if="data.value.is_immediate == 1"
                >ACİL</span
              >
              <span class="font-weight-bold">{{ data.value.due_date }}</span>
            </div>
          </template>
          <template #cell(current_status)="data">
            {{ data.value.status_name }}
          </template>
        </b-table>
      </div>

      <div class="card-body pt-0" v-if="list_device.length <= 0">
        <div class="text-muted text-center pb-2">
          Bu servis için herhangi bir cihaz bulunmamaktadır.
        </div>
      </div>
    </div>

    <div v-if="sf.device.device_id > 0">
      <div class="card">
        <div class="card-header border-bottom pb-1 pt-1">
          <h4 class="mb-0">Cihaz Bilgileri #{{ sf.device.device_id }}</h4>
        </div>
        <div class="card-body mt-2">
          <div class="row">
            <div class="col-sm-4">
              <div class="form-group">
                <label class="font-medium mb-0 d-block">Müşteri</label>
                <input
                  :value="sf.customer.title"
                  type="text"
                  class="form-control"
                  readonly
                />
              </div>
            </div>

            <div class="col-sm-4">
              <div class="form-group">
                <label class="font-medium mb-0 d-block">GSM</label>
                <input
                  :value="sf.customer.gsm_primary"
                  type="text"
                  class="form-control"
                  readonly
                />
              </div>
            </div>

            <div class="col-sm-4">
              <div class="form-group">
                <label class="font-medium mb-0 d-block">Email</label>
                <input
                  :value="sf.customer.gsm_email"
                  type="text"
                  class="form-control"
                  readonly
                />
              </div>
            </div>

            <div class="col-sm-4">
              <div class="form-group">
                <label class="font-medium mb-0 d-block">Cihaz Türü</label>
                <input
                  type="text"
                  class="form-control"
                  :value="sf.device.device_type_name"
                  readonly
                />
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group">
                <label class="font-medium mb-0 d-block">Marka</label>
                <input
                  type="text"
                  class="form-control"
                  :value="sf.device.brand_name"
                  readonly
                />
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group">
                <label class="font-medium mb-0 d-block">Model</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="sf.device.model_name"
                />
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group">
                <label class="font-medium mb-0 d-block">Barkod</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="sf.device.device_barcode"
                />
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group">
                <label class="font-medium mb-0 d-block">Seri No</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="sf.device.device_serial_number"
                />
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group">
                <label class="font-medium mb-0 d-block">Yedek Durumu</label>
                <span>{{ sf.device.backup_name }}</span>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group">
                <label class="font-medium mb-0 d-block">Sistem Parolası</label>
                <input
                  type="text"
                  class="form-control"
                  :value="sf.device.password_system"
                  readonly
                />
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group">
                <label class="font-medium mb-0 d-block">Bios Parolası</label>
                <input
                  type="text"
                  class="form-control"
                  :value="sf.device.password_bios"
                  readonly
                />
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group">
                <label class="font-medium mb-0 d-block"
                  >Çıkış Yapılması Gereken Tarih</label
                >
                <span v-if="sf.device.due_date != null">{{
                  moment(sf.device.due_date).format("DD.MM.YYYY")
                }}</span>
              </div>
            </div>

            <div class="col-sm-4">
              <div class="form-group">
                <label class="font-medium mb-0 d-block text-danger"
                  >Acil Mi</label
                >
                <span
                  v-if="sf.device.is_immediate == true"
                  class="badge badge-danger"
                  >Evet</span
                >
                <span v-if="sf.device.is_immediate == false"
                  >Acil Çıkış Gerekmiyor</span
                >
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group">
                <label class="font-medium mb-0 d-block text-danger"
                  >Problemli Cihaz</label
                >
                <span
                  v-if="sf.device.is_hasproblem == true"
                  class="badge badge-danger"
                  >Evet</span
                >
                <span
                  v-if="sf.device.is_hasproblem == false"
                  class="badge badge-secondary"
                  >Hayır</span
                >
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group">
                <label class="font-medium mb-0 d-block">Sorumlu Personel</label>
                <span>{{ sf.device.related_staff_name }}</span>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label class="font-medium mb-0 d-block">Durum</label>
            <v-select
              v-model="sf.device.status_id"
              :options="device_status"
              :searchable="false"
              :clearable="false"
              :reduce="(curr) => curr.value"
            ></v-select>
          </div>
          <div class="form-group">
            <button @click="formSubmit()" class="btn btn-primary">
              Kaydet
            </button>
          </div>
        </div>
      </div>

      <b-card>
        <b-tabs pills>
          <b-tab title="Müşteri Talepleri ve Ekspertiz" active>
            <div class="card p-0">
              <div class="card-header border-bottom">
                <h4 class="card-title">Müşteri Talepleri</h4>
              </div>
              <div class="card-body mt-1">
                <div class="form-group">
                  <label class="font-medium mb-0 d-block">Notlar</label>
                  <div v-if="sf.device.customer_device_notes != ''">
                    {{ sf.device.customer_device_notes }}
                  </div>
                  <div v-else class="text-muted">
                    Herhangi bir talep notu eklenmedi.
                  </div>
                </div>
                <div
                  class="form-group"
                  v-if="sf.device.customer_requests.length > 0"
                >
                  <ul class="list-group">
                    <li
                      class="list-group-item"
                      v-for="(item, index) in sf.device.customer_requests"
                      :key="index"
                    >
                      {{ item.definition_name }}
                      <div>{{ item.notes }}</div>
                    </li>
                  </ul>
                </div>
                <div class="form-group" v-if="_request_messages.length > 0">
                  <hr />
                  <label class="font-medium text-uppercase text-danger"
                    >Müşteri Mesajları ({{ _request_messages.length }})</label
                  >
                  <ul class="list-group">
                    <li
                      class="list-group-item"
                      v-for="(item, index) in _request_messages"
                      :key="index"
                    >
                      <div>
                        <div class="d-flex justify-content-between">
                          <span class="text-uppercase font-medium">Mesaj</span>
                          <span>{{
                            moment(item.created_props.created_at).format(
                              "DD.MM.YYYY HH:mm"
                            )
                          }}</span>
                        </div>
                        <div class="form-group">
                          {{ item.message }}
                        </div>
                        <div class="form-group">
                          <div v-if="item.status == 'unread'">
                            <span class="badge badge-danger">Okunmadı</span>
                            <span
                              class="ml-1 badge badge-secondary cursor-pointer"
                              @click="messageSave(item, 'read')"
                              >Okundu Olarak İşaretle</span
                            >
                          </div>
                          <div v-else>
                            <span class="badge badge-success">Okundu</span>
                            <span v-if="item.status_props != null" class="ml-1">
                              {{
                                moment(item.status_props.created_at).format(
                                  "DD.MM.YYYY HH:mm"
                                )
                              }}
                              &nbsp;
                              {{ item.status_props.created_by_name }}
                            </span>
                          </div>
                        </div>
                        <div class="form-group">
                          <label class="text-uppercase font-medium"
                            >Yanıt</label
                          >
                          <textarea
                            class="form-control"
                            v-model="item.message_answer"
                            :readonly="true"
                          ></textarea>

                          <div v-if="item.message_answer_props != null">
                            {{
                              moment(
                                item.message_answer_props.created_at
                              ).format("DD.MM.YYYY HH:mm")
                            }}
                            tarihinde
                            {{ item.message_answer_props.created_by_name }}
                            tarafından yanıtlandı.
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="card p-0">
              <div class="card-header border-bottom">
                <h4 class="card-title">Ön Ekspertiz</h4>
              </div>
              <div class="card-body pt-1 pb-0">
                <div class="form-group">
                  <label class="font-medium mb-0 d-block">Notlar</label>
                  <div v-if="sf.device.pre_ekspertise_notes != ''">
                    {{ sf.device.pre_ekspertise_notes }}
                  </div>
                  <div v-else class="text-muted">
                    Ön ekspertiz için herhangi bir not eklenmedi.
                  </div>
                </div>
                <div
                  class="form-group"
                  v-if="sf.device.pre_ekspertise.length > 0"
                >
                  <ul class="list-group">
                    <li
                      class="list-group-item"
                      v-for="(item, index) in sf.device.pre_ekspertise"
                      :key="index"
                    >
                      {{ item.definition_name }}
                      <div>{{ item.notes }}</div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div>
              <div class="row coveritems">
                <div class="col-md-3 col-sm-6 coverbox mb-2">
                  <div v-if="getCoverPhoto('cover_a') == null">
                    <b-img
                      @click="triggerFileUpload('cover_a')"
                      thumbnail
                      fluid
                      src="@/assets/images/device_cover_a.png" />
                    <button
                      class="btn btn-sm btn-primary btn-block"
                      @click="triggerFileUpload('cover_a')"
                      style="margin-top: 0.5rem; margin-bottom: 0.25rem">
                      <span
                        v-if="datasource.is_loading_file_uploads.cover_a"
                        class="mr-1">
                        <b-spinner small label="Loading..."></b-spinner>
                      </span>
                      <span>Fotoğraf Seç - A Cover</span>
                    </button>
                    <button
                      class="btn btn-secondary btn-block"
                      @click="startUploadDeviceCover('cover_a')"
                      :disabled="!Boolean(datasource.file_uploads.cover_a)"
                    >
                      Yüklemeyi Başlat
                    </button>
                  </div>
                  <div v-if="getCoverPhoto('cover_a') != null">
                    <b-img
                      @click="showImageModal(getCoverPhoto('cover_a').fullurl)"
                      thumbnail
                      fluid
                      :src="getCoverPhoto('cover_a').fullurl"
                    />
                    <button
                    @click="triggerFileUpload('cover_a')"
                      class="btn  btn-primary btn-block"
                      style="margin-top: 0.5rem; margin-bottom: 0.25rem"
                    >
                      Fotoğraf Seç - A Cover
                    </button>
                    <button
                      @click="deleteDeviceCoverPhoto('cover_a')"
                      class="btn  btn-secondary btn-block"
                    >
                      Sil
                    </button>
                  </div>
                  <b-form-file
                    accept="image/*"
                    id="fuCovers_cover_a"
                    ref="fuCovers_cover_a"
                    v-model="datasource.file_uploads.cover_a"
                    class="hidden"
                  ></b-form-file>
                </div>

                <div class="col-md-3 col-sm-6 coverbox mb-2">
                  <div v-if="getCoverPhoto('cover_b') == null">
                    <b-img
                      @click="triggerFileUpload('cover_b')"
                      thumbnail
                      fluid
                      src="@/assets/images/device_cover_b.png"
                    />
                    <button
                      class="btn btn-sm btn-primary btn-block"
                      @click="triggerFileUpload('cover_b')"
                      style="margin-top: 0.5rem; margin-bottom: 0.25rem"
                    >
                      <span
                        v-if="datasource.is_loading_file_uploads.cover_b"
                        class="mr-1"
                      >
                        <b-spinner small label="Loading..."></b-spinner>
                      </span>
                      <span>Fotoğraf Seç - B Cover</span>
                    </button>
                    <button
                      class="btn btn-sm btn-secondary btn-block"
                      @click="startUploadDeviceCover('cover_b')"
                      :disabled="!Boolean(datasource.file_uploads.cover_b)"
                    >
                      Yüklemeyi Başlat
                    </button>
                  </div>
                  <div v-if="getCoverPhoto('cover_b') != null">
                    <b-img
                      @click="showImageModal(getCoverPhoto('cover_b').fullurl)"
                      thumbnail
                      fluid
                      :src="getCoverPhoto('cover_b').fullurl"
                    />
                    <button
                      class="btn btn-sm btn-primary btn-block"
                     @click="triggerFileUpload('cover_b')"
                      style="margin-top: 0.5rem; margin-bottom: 0.25rem"
                    >
                      Fotoğraf Seç - B Cover
                    </button>
                    <button
                      @click="deleteDeviceCoverPhoto('cover_b')"
                      class="btn btn-sm btn-secondary btn-block"
                    >
                      Sil
                    </button>
                  </div>
                  <b-form-file
                    accept="image/*"
                    id="fuCovers_cover_b"
                    ref="fuCovers_cover_b"
                    v-model="datasource.file_uploads.cover_b"
                    class="hidden"
                  ></b-form-file>
                </div>

                <div class="col-md-3 col-sm-6 coverbox mb-2">
                  <div v-if="getCoverPhoto('cover_c') == null">
                    <b-img
                      @click="triggerFileUpload('cover_c')"
                      thumbnail
                      fluid
                      src="@/assets/images/device_cover_c.png"
                    />
                    <button
                      class="btn btn-sm btn-primary btn-block"
                      @click="triggerFileUpload('cover_c')"
                      style="margin-top: 0.5rem; margin-bottom: 0.25rem"
                    >
                      <span
                        v-if="datasource.is_loading_file_uploads.cover_c"
                        class="mr-1"
                      >
                        <b-spinner small label="Loading..."></b-spinner>
                      </span>
                      <span>Fotoğraf Seç - C Cover</span>
                    </button>
                    <button
                      class="btn btn-sm btn-secondary btn-block"
                      @click="startUploadDeviceCover('cover_c')"
                      :disabled="!Boolean(datasource.file_uploads.cover_c)"
                    >
                      Yüklemeyi Başlat
                    </button>
                  </div>
                  <div v-if="getCoverPhoto('cover_c') != null">
                    <b-img
                      @click="showImageModal(getCoverPhoto('cover_c').fullurl)"
                    
                      thumbnail
                      fluid
                      :src="getCoverPhoto('cover_c').fullurl"
                    />
                    <button
                      class="btn btn-sm btn-primary btn-block"
                       @click="triggerFileUpload('cover_c')"
                      style="margin-top: 0.5rem; margin-bottom: 0.25rem"
                    >
                      Fotoğraf Seç - C Cover
                    </button>
                    <button
                      @click="deleteDeviceCoverPhoto('cover_c')"
                      class="btn btn-sm btn-secondary btn-block"
                    >
                      Sil
                    </button>
                  </div>
                  <b-form-file
                    accept="image/*"
                    id="fuCovers_cover_c"
                    ref="fuCovers_cover_c"
                    v-model="datasource.file_uploads.cover_c"
                    class="hidden"
                  ></b-form-file>
                </div>

                <div class="col-md-3 col-sm-6 coverbox mb-2">
                  <div v-if="getCoverPhoto('cover_d') == null">
                    <b-img
                      @click="triggerFileUpload('cover_d')"
                      thumbnail
                      fluid
                      src="@/assets/images/device_cover_d.png"
                    />
                    <button
                      class="btn btn-sm btn-primary btn-block"
                      @click="triggerFileUpload('cover_d')"
                      style="margin-top: 0.5rem; margin-bottom: 0.25rem"
                    >
                      <span
                        v-if="datasource.is_loading_file_uploads.cover_d"
                        class="mr-1"
                      >
                        <b-spinner small label="Loading..."></b-spinner>
                      </span>
                      <span>Fotoğraf Seç - D Cover</span>
                    </button>
                    <button
                      class="btn btn-sm btn-secondary btn-block"
                      @click="startUploadDeviceCover('cover_d')"
                      :disabled="!Boolean(datasource.file_uploads.cover_d)"
                    >
                      Yüklemeyi Başlat
                    </button>
                  </div>
                  <div v-if="getCoverPhoto('cover_d') != null">
                    <b-img
                    
                       @click="showImageModal(getCoverPhoto('cover_d').fullurl)"
                      thumbnail
                      fluid
                      :src="getCoverPhoto('cover_d').fullurl"
                    />
                    <button
                      class="btn btn-sm btn-primary btn-block"
                       @click="triggerFileUpload('cover_d')"
                      style="margin-top: 0.5rem; margin-bottom: 0.25rem"
                    >
                      Fotoğraf Seç - D Cover
                    </button>
                    <button
                      @click="deleteDeviceCoverPhoto('cover_d')"
                      class="btn btn-sm btn-secondary btn-block"
                    >
                      Sil
                    </button>
                  </div>
                  <b-form-file
                    accept="image/*"
                    id="fuCovers_cover_d"
                    ref="fuCovers_cover_d"
                    v-model="datasource.file_uploads.cover_d"
                    class="hidden"
                  ></b-form-file>
                </div>

                <div class="col-md-3 col-sm-6 coverbox mb-2">
                  <div v-if="getCoverPhoto('cover_sag') == null">
                    <b-img
                      @click="triggerFileUpload('cover_sag')"
                      thumbnail
                      fluid
                      src="@/assets/images/device_cover_sag.png"
                    />
                    <button
                      class="btn btn-sm btn-primary btn-block"
                      @click="triggerFileUpload('cover_sag')"
                      style="margin-top: 0.5rem; margin-bottom: 0.25rem"
                    >
                      <span
                        v-if="datasource.is_loading_file_uploads.cover_sag"
                        class="mr-1"
                      >
                        <b-spinner small label="Loading..."></b-spinner>
                      </span>
                      <span>Fotoğraf Seç - Sağ Cover</span>
                    </button>
                    <button
                      class="btn btn-sm btn-secondary btn-block"
                      @click="startUploadDeviceCover('cover_sag')"
                      :disabled="!Boolean(datasource.file_uploads.cover_sag)"
                    >
                      Yüklemeyi Başlat
                    </button>
                  </div>
                  <div v-if="getCoverPhoto('cover_sag') != null">
                    <b-img
                     @click="showImageModal(getCoverPhoto('cover_sag').fullurl)"
                     
                      thumbnail
                      fluid
                      :src="getCoverPhoto('cover_sag').fullurl"
                    />
                    <button
                      class="btn btn-sm btn-primary btn-block"
                      @click="triggerFileUpload('cover_sag')"
                      style="margin-top: 0.5rem; margin-bottom: 0.25rem"
                    >
                      Fotoğraf Seç - Sağ Cover
                    </button>
                    <button
                      @click="deleteDeviceCoverPhoto('cover_sag')"
                      class="btn btn-sm btn-secondary btn-block"
                    >
                      Sil
                    </button>
                  </div>
                  <b-form-file
                    accept="image/*"
                    id="fuCovers_cover_sag"
                    ref="fuCovers_cover_sag"
                    v-model="datasource.file_uploads.cover_sag"
                    class="hidden"
                  ></b-form-file>
                </div>

                <div class="col-md-3 col-sm-6 coverbox mb-2">
                  <div v-if="getCoverPhoto('cover_sol') == null">
                    <b-img
                      @click="triggerFileUpload('cover_sol')"
                      thumbnail
                      fluid
                      src="@/assets/images/device_cover_sol.png"
                    />
                    <button
                      class="btn btn-sm btn-primary btn-block"
                      @click="triggerFileUpload('cover_sol')"
                      style="margin-top: 0.5rem; margin-bottom: 0.25rem"
                    >
                      <span
                        v-if="datasource.is_loading_file_uploads.cover_sol"
                        class="mr-1"
                      >
                        <b-spinner small label="Loading..."></b-spinner>
                      </span>
                      <span>Fotoğraf Seç - Sol Cover</span>
                    </button>
                    <button
                      class="btn btn-sm btn-secondary btn-block"
                      @click="startUploadDeviceCover('cover_sol')"
                      :disabled="!Boolean(datasource.file_uploads.cover_sol)"
                    >
                      Yüklemeyi Başlat
                    </button>
                  </div>
                  <div v-if="getCoverPhoto('cover_sol') != null">
                    <b-img
                    
                       @click="showImageModal(getCoverPhoto('cover_sol').fullurl)"
                      thumbnail
                      fluid
                      :src="getCoverPhoto('cover_sol').fullurl"
                    />
                    <button
                      class="btn btn-sm btn-primary btn-block"
                       @click="triggerFileUpload('cover_sol')"
                      style="margin-top: 0.5rem; margin-bottom: 0.25rem"
                    >
                      Fotoğraf Seç - Sol Cover
                    </button>
                    <button
                      @click="deleteDeviceCoverPhoto('cover_sol')"
                      class="btn btn-sm btn-secondary btn-block"
                    >
                      Sil
                    </button>
                  </div>
                  <b-form-file
                    accept="image/*"
                    id="fuCovers_cover_sol"
                    ref="fuCovers_cover_sol"
                    v-model="datasource.file_uploads.cover_sol"
                    class="hidden"
                  ></b-form-file>
                </div>
              </div>
            </div>
          </b-tab>
          <b-tab title="Teknik Rapor">
            <div class="card p-0">
              <div class="card-header border-bottom">
                <h4 class="card-title">Rapor</h4>
              </div>
              <div class="card-body mt-1">
                <div class="form-group">
                  <label class="text-uppercase font-medium"
                    >Sorun/Problem</label
                  >
                  <textarea
                    v-model="device_problem.issue"
                    rows="8"
                    class="form-control"
                    placeholder="Cihazdaki Sorunları Yazın"
                  ></textarea>
                </div>
                <div class="form-group">
                  <label class="text-uppercase font-medium"
                    >Çözüm Önerisi</label
                  >
                  <textarea
                    v-model="device_problem.suggestion"
                    rows="8"
                    class="form-control"
                    placeholder="Çözüm Önerisi ve Notlar"
                  ></textarea>
                </div>
                <div class="form-group">
                  <label class="text-uppercase font-semibold text-danger"
                    >Yapılacak İşlemler</label
                  >
                  <textarea
                    v-model="device_problem.todoaction"
                    rows="8"
                    class="form-control"
                    placeholder="Yapılacak İşlemler"
                    readonly
                  ></textarea>
                </div>
                <div class="form-group">
                  <label class="text-uppercase font-medium"
                    >Yapılan İşlemler</label
                  >
                  <textarea
                    v-model="device_problem.solution"
                    rows="8"
                    class="form-control"
                    placeholder="Yapılan İşlemler"
                  ></textarea>
                </div>
                <div class="form-group">
                  <label class="text-uppercase font-medium">Tür</label>
                  <v-select
                    v-model="device_problem.type"
                    :options="global.device_problem_types"
                    :searchable="false"
                    :clearable="false"
                    :disabled="true"
                    :reduce="(curr) => curr.value"
                  >
                  </v-select>
                </div>
                <div
                  class="form-group"
                  v-if="device_problem.approved_props != null"
                >
                  <label class="text-uppercase font-medium">Onaylayan</label>
                  <div>
                    {{ device_problem.approved_props.approved_by_name }} /
                    {{
                      moment(device_problem.approved_props.approved_at).format(
                        "DD.MM.YYYY HH:mm"
                      )
                    }}
                  </div>
                </div>
                <div
                  class="form-group"
                  v-if="device_problem.rejected_props != null"
                >
                  <label class="text-uppercase font-medium">Reddeden</label>
                  <div>
                    {{ device_problem.rejected_props.rejected_by_name }} /
                    {{
                      moment(device_problem.rejected_props.rejected_at).format(
                        "DD.MM.YYYY HH:mm"
                      )
                    }}
                  </div>
                </div>
                <div class="form-group">
                  <label class="text-uppercase font-medium">TEST</label>
                  <service-device-repair-test></service-device-repair-test>
                </div>
                <div class="form-group">
                  <button
                    class="btn btn-primary"
                    v-on:click="saveEntityDeviceProblem()"
                  >
                    Kaydet
                  </button>
                </div>
              </div>
            </div>
          </b-tab>
          <b-tab title="Teknik Görseller">
            <b-container
              v-if="_files.length > 0"
              fluid
              class="p-1 mt-2 mb-2 bg-dark"
            >
              <b-row>
                <b-col
                  lg="4"
                  sm="6"
                  class="p-1"
                  v-for="file in _files"
                  :key="file.fid"
                >
                  <div>
                    <b-img thumbnail fluid :src="file.fullurl" />
                    <div
                      class="
                        mt-1
                        d-flex
                        align-items-center
                        justify-content-between
                      "
                    >
                      <div>
                        <div class="text-white">
                          {{ file.created_props.created_at }}
                        </div>
                        <div class="text-white">
                          {{ file.created_props.created_by_name }}
                        </div>
                      </div>
                      <button
                        v-on:click="removeFile(file)"
                        class="btn btn-sm btn-danger mt-1"
                      >
                        Sil
                      </button>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-container>
            <service-upload-technical
              entity="device_technical_report"
              :entityid="sf.device.device_id"
              :sfid="sf.sform.sf_id"
            ></service-upload-technical>
          </b-tab>
        </b-tabs>
      </b-card>
    </div>

    <b-modal id="image-modal" size="lg" hide-footer>
      <template #modal-title>
        Resmi Görüntüle
      </template>
      <b-img
        :src="selectedImageUrl"
        fluid
        class="w-100"
      />
    </b-modal>

  </div>
</template>

<script>
import { VueAutosuggest } from "vue-autosuggest";
import utils from "@/service/utils";
import api from "@/service/api";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import _ from "lodash";
import ServiceDevice from "./ServiceDevice.vue";
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import ServiceUploadTechnical from "./ServiceUploadTechnical.vue";
import ServiceDeviceRepairTest from "./ServiceDeviceRepairTest";
import { image } from "vee-validate/dist/rules";

export default {
  components: {
    VueAutosuggest,
    vSelect,
    flatPickr,
    ServiceDevice,
    ServiceUploadTechnical,
    ServiceDeviceRepairTest,
  },
  data() {
    return {
      qp: { uniq: null },
      datasource: {
        table_device_list: {
          selected: null,
          fields: [
            {
              key: "device_id",
              label: "No",
              sortable: false,
              selected: true,
            },
            {
              key: "device_name",
              label: "Cihaz",
              sortable: false,
              selected: true,
            },
            {
              key: "device_barcode",
              label: "Barkod",
              sortable: false,
              selected: true,
            },
            {
              key: "current_status",
              label: "Durum",
              sortable: false,
              selected: true,
            },
            {
              key: "due_date_item",
              label: "Çıkış Yapılacak Tarih",
              sortable: false,
              selected: true,
            },
          ],
        },
        file_uploads: {
          cover_a: null,
          cover_b: null,
          cover_c: null,
          cover_d: null,
          cover_sag: null,
          cover_sol: null,
        },
        is_loading_file_uploads: {
          cover_a: false,
          cover_b: false,
          cover_c: false,
          cover_d: false,
          cover_sag: false,
          cover_sol: false,
        },

      },
      selectedImageUrl:''
    };
  },
  computed: {
    ...mapState({
      sf: (state) => state.sf,
      global: (state) => state.global,
    }),
    list_device() {
      let result = [];
 
      this.sf.devices.forEach((q) => {
        let device_name = q.model_name;
        let brand = utils.getDefinition(q.brand_id);
        if (brand != null) device_name = brand.name + " " + q.model_name;
        let passing_time = "1 Gün Geçti";
        let item = {
          device_id: q.device_id,
          device_name: device_name,
          device_barcode: q.device_barcode,
          current_status: q.current_status,
          due_date_item: {
            due_date: utils.moment(q.due_date).isValid()
              ? utils.moment(q.due_date).format("DD.MM.YYYY")
              : "",
            is_immediate: q.is_immediate,
            passing_time: passing_time,
          },
          item: q,
        };
        result.push(item);
      });

      return result;
    },
    device_problem() {
      let result = {};
      if (this.sf.device.device_problems.length == 1) {
        result = this.sf.device.device_problems[0];
      } else if (this.sf.device.device_problems.length == 0) {
        result = {
          dp_id: "0",
          issue: null,
          solution: null,
          status: "pending",
          suggestion: null,
          tasks: [],
          type: "problem",
        };
      }
      return result;
    },
    device_status() {
      let result = [];
 
      this.sf.device.device_status.forEach((q) => {
        let item = {
          value: q.status_id,
          label: q.status_name,
          item: q,
        };
        result.push(item);
      });
      return result;
    },
    _files() {
      let result = [];
      if (this.sf.device.files) {
        result = this.sf.device.files.filter(
          (q) => q.entity == "device_technical_report"
        );
      }
      return result;
    },
    _request_messages() {
      return this.sf.device.request_messages;
    },
    _other_sf() {
      let result = [];
      if (this.sf.device.other_sf && this.sf.device.other_sf.length > 0) {
        result = this.sf.device.other_sf;
      }
      return result;
    },
  },
  created() {
    this.qp.uniq = this.$route.query.uniq;
    if (this.qp.uniq == null) {
      utils.notificationToast("warning", "Bilgi", "Geçerli bir servis seçin.");
      return;
    }
    this.sf.sform.sf_uniq = this.qp.uniq;
    this.getServiceDetails();
  },
  methods: {
    showImageModal(imageUrl) {
      console.log('dsd',imageUrl);
      this.selectedImageUrl = imageUrl; // Resmin URL'sini sakla
      this.$bvModal.show('image-modal'); // Modalı aç
    },
    ...mapActions([
      "saveDevice",
      "getServiceDetails",
      "saveDeviceProblem",
      "saveDeviceProblemTask",
      "saveDeviceRepairTask",
      "saveDeviceRequesMessage",
    ]),
    ...mapMutations(["clearSF","setSDevice"]),
    moment(v) {
      return utils.moment(v);
    },
    redirectto(item) {
      let route = this.$router.resolve({
        path: "/tservice",
        query: { uniq: item.sf_uniq },
      });
      window.open(route.href);
    },
    setDevice(item) {
     //    this.$store.dispatch("clearSF");

  

       

 

      try {
        if (item == null || item[0] == null || item.length == 0) {
          //this.newDevice();
          return;
        }
        this.sf.device = Object.assign({}, item[0].item);
      } catch (error) {
        utils.logError(error.stack, "ServiceDeviceList", "setDevice");
      }
 

    },
    rowClass(item, type) {
      if (!item || type !== "row") {
        return;
      }
    },
    saveEntityDeviceProblem() {
      let d = Object.assign({}, this.device_problem);
      this.sf.device_problem = d;
      this.saveDeviceProblem();
    },
    messageSave(item, action) {
      item.m_action = action;
      this.saveDeviceRequesMessage(item).then((q) => {
        if (q.success) {
          item.status = q.data.status;
          item.status_props = q.data.status_props;
          item.message_answer = q.data.message_answer;
          item.message_answer_props =
            q.data.message_answer_props != null
              ? JSON.parse(q.data.message_answer_props)
              : null;
          item.updated_props = JSON.parse(q.data.updated_props);
        }
      });
    },
    formSubmit() {
      this.saveDevice().then((q) => {
        if (q.success == true) {
          // utils.notificationToast("success", "Bilgi", q.message);
        } else {
          //  utils.notificationToast("warning", "Uyarı", q.message);
        }
      });
    },
    removeFile(file)
    {
     api.action("removeFile", { funiq: file.funiq }).then((q) => {
          if (q.success) {
            this.sf.device.files = this.sf.device.files.filter(
              (qq) => qq.fid != file.fid
            );
            utils.notificationToast("success", "Seçilen fotoğraf silindi.");
            return;
          } else {
            utils.notificationToast("warning", q.message);
            return;
          }
        });
    },
    deleteDeviceCoverPhoto(target) {
      let funiq = this.sf.device.files.find(
        (q) => q.attr_1 == target && q.entity_id == this.sf.device.device_id
      );
      if (funiq != null) {
        api.action("removeFile", { funiq: funiq.funiq }).then((q) => {
          if (q.success) {
            this.sf.device.files = this.sf.device.files.filter(
              (qq) => qq.fid != funiq.fid
            );
            utils.notificationToast("success", "Seçilen fotoğraf silindi.");
            return;
          } else {
            utils.notificationToast("warning", q.message);
            return;
          }
        });
      }
    },
    getCoverPhoto(target) {
      let result = null;
      if (this.sf.device.files) {
        result = this.sf.device.files.find(
          (q) => q.attr_1 == target && q.entity_id == this.sf.device.device_id
        );
      }
      return result;
    },
    triggerFileUpload(target) {
      if (this.datasource.is_loading_file_uploads[target] == true) return;
      this.$refs[`fuCovers_${target}`].$el.childNodes[0].click();
    },
    startUploadDeviceCover(target) {
      if (this.datasource.is_loading_file_uploads[target] == true) return;
      let file = this.$refs[`fuCovers_${target}`].files[0];
      let data = {
        entity: "device_cover_photo",
        entityid: this.sf.device.device_id,
        entitygroup: "service_form",
        entitygroupid: this.sf.sform.sf_id,
        cover: target,
      };
      this.datasource.is_loading_file_uploads[target] = true;
      api.upload(file, data).then((q) => {
        if (q.success) {
          this.sf.device.files.push(q.data);
        } else {
          utils.notificationToast("warning", q.message);
        }
        this.datasource.is_loading_file_uploads[target] = false;
      });
    },
  },
};
</script>
 